if (typeof(Storage) !== "undefined" && sessionStorage.getItem("fontsLoaded") == "true") {
    var htmlEl = document.documentElement;
    if (!htmlEl.classList.contains("fonts-loaded")) {
        htmlEl.classList.add("fonts-loaded");
    }
}
else {

    var ArchivoNarrow400 = new FontFaceObserver("Archivo Narrow", {weight: 400, style: "normal"});
    var Archivo400 = new FontFaceObserver("Archivo", {weight: 400, style: "normal"});

    Promise.all([
    	ArchivoNarrow400.load(),
        Archivo400.load()
    ])
    .then(function() {
        document.documentElement.classList.add("fonts-loaded");
        if (typeof(Storage) !== "undefined") {
        	sessionStorage.setItem("fontsLoaded", "true");
        }
    })
    .catch(function (err) {
        document.documentElement.classList.remove("fonts-loaded");
        if (typeof(Storage) !== "undefined") {
        	sessionStorage.setItem("fontsLoaded", "false");
        }
        console.warn("Some fonts did not load:", err);
    });
}
